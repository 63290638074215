/* eslint-disable */
import instance from "@/services/instance.js";

const GetAllVenue = payload => {
  return instance.instanceToken
    .get("/venue/all", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const GetAllVenueProfile = payload => {
  return instance.instanceToken
    .get("/venue/profile/all", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const GetVenueProfileByCity = payload => {
  return instance.instanceToken
    .post("/venue/profileGetByCity", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const UpdateVenueCity = payload => {
  return instance.instanceToken
    .post("/venue/updateCity", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const SetDelete = payload => {
  return instance.instanceToken
    .post("/venue/delete", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const setWTTCCertificate = payload => {
  return instance.instanceToken
    .get("venue/isWTTCCertificate/" + payload._id)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const InviteVenue = (payload) => {
  return instance.instance
    .post("venue/invite", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const UpdateAccount = payload => {
  return instance.instanceToken
    .post("/venue/admin_update", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
export default {
  //   logIn: LogIn,
  inviteVenue: InviteVenue,
  getAllVenueProfile: GetAllVenueProfile,
  getAllVenue: GetAllVenue,
  getVenueProfileByCity: GetVenueProfileByCity,
  setDelete: SetDelete,
  SetWTTCCertificate: setWTTCCertificate,
  UpdateVenueCity: UpdateVenueCity,
  updateAccount:UpdateAccount
};

<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3 venueDetailsContainer">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Venue View</div>
            <div
              class="d-flex flex-row align-center justify-start subtitle-1 font-weight-light"
            >
              <span>This shows the Venue profile</span>
            </div>
          </template>
          <div>
            <v-overlay
              :value="overlay"
              opacity="1"
              color="white"
              z-index="0"
              absolute="absolute"
            >
              <v-progress-circular indeterminate size="100" color="primary">
              </v-progress-circular>
            </v-overlay>
          </div>
          <v-card-text>
            <v-row
              class="d-flex flex-row align-start justify-space-between"
              v-if="details"
            >
              <!-- venue details -->
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">Venue Details</h3>
                  </v-col>
                </v-row>
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Venue Name:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.user.venueName"
                    >{{ details.user.venueName }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Venue Website:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip
                      bottom
                      v-if="details.profile && details.profile.website"
                    >
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a
                            :href="validateUrl(details.profile.website)"
                            target="_blank"
                          >{{ validateUrl(details.profile.website) }}</a
                          >
                        </span>
                      </template>
                      <span>
                        Visit
                        <b>
                          <i>{{ validateUrl(details.profile.website) }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Conferli URL:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip
                      bottom
                      v-if="details.profile && details.profile._id && details.profile.venueUrl && !details.profile.venueUrl.toString().includes('undefined')"
                    >
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a
                            :href="getRouteName(details.profile.venueUrl)"
                            target="_blank"
                          >{{ getRouteName(details.profile.venueUrl) }}</a
                          >
                        </span>
                      </template>
                      <span>
                        Visit
                        <b>
                          <i>{{ getRouteName(details.profile.venueUrl) }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Venue Address:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      v-if="details.profile && details.profile.address"
                      class="subtitle-1 text-left"
                    >{{
                        `${
                          details.profile.address.street
                            ? details.profile.address.street
                            : ""
                        } ${
                          details.profile.address.postalCode
                            ? `[${details.profile.address.postalCode}]`
                            : ""
                        } ${
                          details.profile.address.city
                            ? `, ${details.profile.address.city}, `
                            : ""
                        }${
                          details.profile.address.country
                            ? `${details.profile.address.country}`
                            : ""
                        }`
                      }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >
                    n/a
                    <v-btn v-on="on" icon color="grey lighten" href="#update_location">
                          <font-awesome-icon :icon="['fas', 'pen-alt']"/>
                        </v-btn>
                    </span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Social Media Channels:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-row
                      class="d-flex flex-row flex-wrap mx-0"
                      v-if="
                        details.profile &&
                          details.profile.socialMedia.length > 0
                      "
                    >
                      <v-col
                        v-for="socialMedia in details.profile.socialMedia"
                        :key="socialMedia._id"
                        v-show="socialMedia.link"
                        class="py-0 pl-0 flex-grow-0"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-on="on"
                              class="mr-2 mb-2"
                              label
                              :href="validateUrl(socialMedia.link)"
                              target="_blank"
                              :color="getSocialColor(socialMedia.name)"
                            >
                              <font-awesome-icon
                                :icon="['fab', socialMedia.icon]"
                                class="mr-2"
                                size="2x"
                                color="white"
                              />
                              <span
                                class="text-left caption text-capitalize white--text"
                              >{{ socialMedia.name }}</span
                              >
                            </v-chip>
                          </template>
                          <span>{{ validateUrl(socialMedia.link) }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex flex-row mx-0" v-else>
                      <span
                        class="subtitle-1 text-left platinum--text text--darken-3"
                      >n/a</span
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
              </v-col>
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">
                      Covid-19 Health Regulations
                    </h3>
                  </v-col>
                </v-row>
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Health regulations:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile.healthRegulations"
                    >{{ details.profile.healthRegulations }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
              </v-col>
              <!-- venue specifications -->
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">Venue Specifications</h3>
                  </v-col>
                </v-row>
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Venue Style:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile && details.profile.venueStyle"
                    >{{ details.profile.venueStyle }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Venue Type:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile && details.profile.venueType"
                    >{{ details.profile.venueType }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Max. No. of Delegates (per style):</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-row
                      class="d-flex flex-row flex-wrap mx-0"
                      v-if="
                        details.profile &&
                          details.profile.maxNoOfDelegateStyle.length > 0
                      "
                    >
                      <v-chip
                        class="mr-2 mb-2 grey--text"
                        label
                        :key="index"
                        v-for="(style, index) in details.profile
                          .maxNoOfDelegateStyle"
                      >
                        <span class="subtitle-1 text-left">
                          {{ style.styleName }}:
                          <b v-if="style.maxCount">{{ style.maxCount }}</b>
                          <span class="platinum--text text--darken-3" v-else
                          >n/a</span
                          >
                        </span>
                      </v-chip>
                    </v-row>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >No. of Hotel Rooms:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-row
                      class="d-flex flex-row flex-wrap mx-0"
                      v-if="
                        details.profile &&
                          details.profile.noOfHotelRooms.length > 0
                      "
                    >
                      <v-chip
                        class="mr-2 mb-2 grey--text"
                        label
                        :key="index"
                        v-for="(style, index) in details.profile.noOfHotelRooms"
                      >
                        <span class="subtitle-1 text-left">
                          {{ style.roomType }}:
                          <b v-if="style.count">{{ style.count }}</b>
                          <span class="platinum--text text--darken-3" v-else
                          >n/a</span
                          >
                        </span>
                      </v-chip>
                    </v-row>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >No. of Meeting Rooms:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile && details.profile.noOfMeetRooms"
                    >{{ details.profile.noOfMeetRooms }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Size of Biggest Meeting Rooms:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile && details.profile.sizeBigMeetRoom"
                    >{{ `${details.profile.sizeBigMeetRoom} m²` }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >People Capacity (Lunch/Dinner):</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile && details.profile.noOfMeetLunch"
                    >{{ details.profile.noOfMeetLunch }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >People Capacity (Reception/Cocktail):</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="
                        details.profile && details.profile.noOfMeetReception
                      "
                    >{{ details.profile.noOfMeetReception }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Ceiling Height:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile && details.profile.ceilHeight"
                    >{{ `${details.profile.ceilHeight} m²` }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Exhibition Space:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isExhibitSpace
                          ? `${details.profile.exhibitSpace} m²`
                          : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Parking Onsite:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isParkOnsite
                          ? `€${details.profile.parkOnsitePrice}`
                          : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Wheelchair Accessible:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isWheelchairAccesible ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Distance to Main Station:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="
                        details.profile && details.profile.distance2MainStation
                      "
                    >{{ details.profile.distance2MainStation }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Distance to City Centre:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="
                        details.profile && details.profile.distance2CityCentre
                      "
                    >{{ details.profile.distance2CityCentre }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Sustainability Certificate:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <p
                      class="body-1 text-left"
                      v-if="
                        details.profile &&
                          details.profile.isSustainabilityCertificate
                      "
                    >
                      {{ details.profile.sustainabilityCertificate }}
                    </p>
                    <span class="subtitle-1 text-left" v-else>No</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
              </v-col>
              <!-- meeting room facitilies -->
              <v-col cols="12" sm="6" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">Meeting Room Facilities</h3>
                  </v-col>
                </v-row>
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">AV Equipments:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-row
                      class="d-flex flex-row flex-wrap mx-0"
                      v-if="details.profile && details.profile.isAVEquipments"
                    >
                      <v-chip
                        class="mr-2 mb-2 grey--text"
                        label
                        :key="index"
                        v-for="(equipment, index) in details.profile
                          .AVequipments"
                      >
                        <span class="subtitle-1 text-left">{{
                            equipment
                          }}</span>
                      </v-chip>
                    </v-row>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Air Conditioning:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isFacilitiesAircondition ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Hospitality Desk:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isHospitalityDeskAvailable ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Onsite Facilities Team:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isOnSiteFacilityTeam ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Video Conferencing:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isVideoConference ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Daylight in all Rooms:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isDaylightAllRms ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Free WiFi:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isFreeWifi ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
              </v-col>
              <!-- accomodation facitilies -->
              <v-col cols="12" sm="6" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">Accomodation Facilities</h3>
                  </v-col>
                </v-row>
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Gym:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isGym ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">24-hour Reception:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.is24HReception ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Restaurant Onsite:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isRestaurantOnsite ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Inside Swimming Pool:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isInsideSwimmingpool ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Outside Swimming Pool:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isOutsideSwimmingpool ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Air Conditioning:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.profile">{{
                        details.profile.isAccomodationAircondition ? "Yes" : "No"
                      }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
              </v-col>
              <!-- venue rep. details -->
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">
                      Venue (Representative) Details
                    </h3>
                  </v-col>
                </v-row>
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Name:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.user.name"
                    >{{ details.user.name }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Email:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip bottom v-if="details.user.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${details.user.email}`">{{
                              details.user.email
                            }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ details.user.name }}</b> via
                        <b>
                          <i>{{ details.user.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Phone:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip
                      bottom
                      v-if="
                        details.profile &&
                          details.profile.telephoneNo &&
                          details.profile.telephoneNo.no
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a
                            :href="
                              `tel:+${details.profile.telephoneNo.code +
                                details.profile.telephoneNo.no}`
                            "
                          >{{
                              `+${details.profile.telephoneNo.code} ${details.profile.telephoneNo.no}`
                            }}</a
                          >
                        </span>
                      </template>
                      <span>
                        Call
                        <b>{{ details.user.name }}</b> via
                        <b>
                          <i>{{
                              `+${details.profile.telephoneNo.code} ${details.profile.telephoneNo.no}`
                            }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Joined Date:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="computedDateFormat"
                    >{{ computedDateFormat }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
              </v-col>
              <!-- Team details -->
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">Team Details</h3>
                  </v-col>
                </v-row>
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Name (General Manager):</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="
                        details.profile &&
                          details.profile.gmPerson &&
                          details.profile.gmPerson.name
                      "
                    >{{ details.profile.gmPerson.name }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Email (General Manager):</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip
                      bottom
                      v-if="
                        details.profile &&
                          details.profile.gmPerson &&
                          details.profile.gmPerson.emailAddress
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a
                            :href="
                              `mailto:${details.profile.gmPerson.emailAddress}`
                            "
                          >{{ details.profile.gmPerson.emailAddress }}</a
                          >
                        </span>
                      </template>
                      <span>
                        Send an email to
                        {{ details.profile.gmPerson.name ? "" : "the" }}
                        <b>{{
                            details.profile.gmPerson.name
                              ? details.profile.gmPerson.name
                              : "General Manager"
                          }}</b>
                        via
                        <b>
                          <i>{{ details.profile.gmPerson.emailAddress }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Name (RFP):</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="
                        details.profile &&
                          details.profile.RFPPerson &&
                          details.profile.RFPPerson.name
                      "
                    >{{ details.profile.RFPPerson.name }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Email (RFP):</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip
                      bottom
                      v-if="
                        details.profile &&
                          details.profile.RFPPerson &&
                          details.profile.RFPPerson.emailAddress
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a
                            :href="
                              `mailto:${details.profile.RFPPerson.emailAddress}`
                            "
                          >{{ details.profile.RFPPerson.emailAddress }}</a
                          >
                        </span>
                      </template>
                      <span>
                        Send an email to
                        {{ details.profile.RFPPerson.name ? "" : "the" }}
                        <b>{{
                            details.profile.RFPPerson.name
                              ? details.profile.RFPPerson.name
                              : "RFP Person"
                          }}</b>
                        via
                        <b>
                          <i>{{ details.profile.RFPPerson.emailAddress }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Name (Finance):</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="
                        details.profile &&
                          details.profile.finance &&
                          details.profile.finance.name
                      "
                    >{{ details.profile.finance.name }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Email (Finance):</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip
                      bottom
                      v-if="
                        details.profile &&
                          details.profile.finance &&
                          details.profile.finance.emailAddress
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a
                            :href="
                              `mailto:${details.profile.finance.emailAddress}`
                            "
                          >{{ details.profile.finance.emailAddress }}</a
                          >
                        </span>
                      </template>
                      <span>
                        Send an email to
                        {{ details.profile.finance.name ? "" : "the" }}
                        <b>{{
                            details.profile.finance.name
                              ? details.profile.finance.name
                              : "Finance Person"
                          }}</b>
                        via
                        <b>
                          <i>{{ details.profile.finance.emailAddress }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for venue details -->
              </v-col>
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="5">
                    <h3 class="headline text-left">
                      Update Representative Account
                    </h3>
                    <v-divider></v-divider>
                    <v-row class="px-0">
                      <v-col cols="10" style="margin-top: 20px">
                        <v-text-field
                          style="margin-bottom: -40px"
                          type="text"
                          label="Venue Subscriber Name"
                          outlined
                          v-model="name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          style="margin-bottom: -40px"
                          type="text"
                          label="Email"
                          outlined
                          v-model="email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          style="margin-bottom: -40px"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show ? 'text' : 'password'"
                          v-model="password"
                          label="New Password"
                          outlined
                          @click:append="show = !show"
                          :error-messages="errorPassword"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-btn block style="background-color: #35a7ff;" @click="dialog = true">
                          <v-icon left dark>
                            mdi-card-account-details
                          </v-icon>
                          Update Representative Account
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="5">
                    <h3 class="headline text-left">
                      Update Location Details
                    </h3>
                    <v-divider id="update_location"></v-divider>
                    <v-row class="px-0">
                      <v-col cols="5" style="margin-top: 20px">
                        <v-text-field
                          style="margin-bottom: -40px"
                          type="text"
                          label="Street"
                          outlined
                          v-model="selectedStreet"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5" style="margin-top: 20px">
                        <v-text-field
                          style="margin-bottom: -40px"
                          type="text"
                          label="Postal Code"
                          outlined
                          v-model="selectedPostalCode"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-autocomplete
                            :items="cities"
                            v-model="selectedCity"
                            item-text="city"
                            outlined
                            required
                            return-object
                            hide-details="auto"
                            :label="`Search for Cities`"
                          ></v-autocomplete>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          style="margin-bottom: -40px"
                          type="text"
                          label="Country"
                          outlined
                          v-model="selectedCountry"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-col cols="10">
                        <v-btn block style="background-color: #35a7ff;" @click="updateLocation()">
                          <v-icon left dark>
                            mdi mdi-map-marker
                          </v-icon>
                          Update Venue Location
                        </v-btn>
                      </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card height="45vh" style="overflow-y: scroll;padding-right: 10px;padding-left: 10px;padding-top: 20px">
        <v-card-title class="px-2 px-sm-6">
          <v-row class="pb-3">
            <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-0">
              <h2 class="headline text-left">Please confirm</h2>
            </v-col>
            <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
              <v-btn class="dialog-close-btn" icon color="grey lighten-1" @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-if="details.user.email !== email || details.user.name !== name || password !== ''">
            <v-col cols="6" md="6">
              <span class="text-left caption text-capitalize red--text">Current</span>
              <br/>
              <span class="text-left caption text-capitalize black--text"
                    v-if="details.user.name !== name">Name :- {{ details.user.name }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="details.user.email !== email">Email :- {{
                  details.user.email
                }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text"
                    v-if="password !== ''">Password :- hidden</span>
            </v-col>
            <v-col cols="6" md="6">
              <span class="text-left caption text-capitalize green--text">New</span>
              <br/>
              <span class="text-left caption text-capitalize black--text"
                    v-if="details.user.name !== name">Name :- {{ name }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="details.user.email !== email">Email :- {{
                  email
                }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="password !== ''">Password :- {{
                  password === '' ? 'Not Changed!' : password
                }}</span>
            </v-col>
          </v-row>
          <v-row v-else style="text-align: center">
            <span style="text-align: center;width: 100%;margin-top: 10vh"
                  class="text-center caption text-capitalize grey--text font-weight-bold">There is no changes to update!</span>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6"
                        v-if="details.user.email !== email || details.user.name !== name || password !== ''">
          <v-row
            class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0">
            <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-start px-0">
              <v-btn class="px-8" color="secondary" @click="dialog = false" tile outlined depressed bottom>
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-end px-0"
                   v-if="details.user.email !== email || details.user.name !== name || password !== ''">
              <v-btn class="px-8 mx-0" color="secondary" tile depressed bottom right @click="updateProfile">
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Config from "../../../services/config";
import Apicalls from "../../../services/login.service";
import VenueApi from "../../../services/venue.service";
import cityServices from "../../../services/citySubscribers.service";
import venueServices from "../../../services/venue.service";
import {mapGetters} from "vuex";

export default {
  name: "VenueView",
  components: {},
  data() {
    return {
      dialog: false,
      show: false,
      errorPassword: null,
      password: null,
      email: null,
      name: null,
      cities:[],
      selectedCity:null,
      selectedCountry:null,
      selectedPostalCode:null,
      selectedStreet:null,
      staticErrorMessages: {
        email: {
          isEmpty: "Email Address cannot be empty"
        },
        password: {
          isEmpty: "Password cannot be empty"
        }
      },
      details: null,
      overlay: true,
    };
  },
  computed: {
    ...mapGetters({
      email: "getUserEmail"
    }),
    computedDateFormat() {
      return this.details.user.created_at.split("T")[0];
    }
  },
  mounted() {
    this.overlay = true;
    let payload = {
      email: localStorage.getItem("setUserEmail")
    };
    Apicalls.getDetails(payload)
      .then(res => {
        this.details = res.data.content;
        this.overlay = false;
        this.name = this.details.user.name;
        this.email = this.details.user.email;
        this.password = '';

        this.selectedPostalCode = this.details.profile.address.postalCode;
        this.selectedStreet = this.details.profile.address.street;
      })
      .catch();
    cityServices.getAllCityName().then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].city = res.data[i].city.toUpperCase();
      }
      this.cities = res.data;
    });
  },
  methods: {
    currentCity(){
        this.cities.forEach(city => {
          if(city.city.toLowerCase() === this.details.profile.address.city.toLowerCase()){
            this.selectedCity = city;
          }
        });
    },
    updateLocation(){
      venueServices.UpdateVenueCity({
        city: this.selectedCity.city.toLowerCase(),
        venue: this.details.user._id,
        cityId: this.selectedCity._id,
        postal:this.selectedPostalCode,
        street:this.selectedStreet,
        country:this.selectedCountry
      }).then(() => {
        this.$store.dispatch("notify/setSnackbarMessage", {
          snackBarText: "Venue location has been Updated Successfully",
          snackBarColor: "secondary",
        });
        location.reload();
      });
    },
    getSocialColor(name) {
      if (name == "LinkedIn") {
        return "#0a66c2";
      } else if (name == "Twitter") {
        return "#1da1f2";
      } else if (name == "Youtube") {
        return "#ff0000";
      } else if (name == "Instagram") {
        return "#f00075";
      } else if (name == "Vimeo") {
        return "#00adef";
      } else if (name == "Flickr") {
        return "#ff0084";
      } else {
        return "secondary";
      }
    },
    validateUrl(url) {
      if (!url) return;
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");
      if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
      }
      return newUrl;
    },
    getRouteName(id) {
      console.log(Config.userSite)
      return `${Config.userSite}/venues${id}`;
      // return `http://localhost:3000/venues${id}`;
    },
    updateProfile() {
      var input = document.createElement('input');
      input.type = 'email';
      input.required = true;
      input.value = this.email.toLowerCase();
      if (!/\S+@\S+\.\S+/.test(this.email.toLowerCase())) {
        let payload2 = {
          snackBarText: `Given email address is not valid!`,
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
      } else {
        const payload = {
          oldEmail: this.details.user.email,
          newEmail: this.email,
          newName: this.name,
          password: this.password
        }
        VenueApi.updateAccount(payload).then((res) => {
          console.log(res)
          Apicalls.getDetails({email: this.email})
            .then((res) => {
              // console.log(res.data);
              this.details = res.data.content;
              this.isApiload = true;
              this.overlay = false;
              this.name = this.details.user.name;
              this.email = this.details.user.email;
              this.password = '';
            })
            .catch();
          this.dialog = false;
          let payload2 = {
            snackBarText: `Representative Account Updated!`,
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
        }).catch(() => {
          let payload2 = {
            snackBarText: `Something went wrong! \nPlease try again.`,
            snackBarColor: "error",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.dialog = false;
        });
      }
    }
  },
  watch:{
    selectedCity(val){
      this.selectedCountry = val.country
    },
    cities(){
      this.currentCity();
    },
    details(){
      this.currentCity();
    }
  }
};
</script>
<style scoped>
.venueDetailsContainer {
  min-height: 500px;
}
</style>
